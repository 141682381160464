import React from 'react'
import { InboxIcon, SparklesIcon } from '@heroicons/react/24/outline'
import RefreshSubheader from '../RefreshSubheader'
import {appleAppStoreDownloadURL} from '../../Constants';

const Tarifa = () => {
    function openAppleDownloadURL() {
        window.open(appleAppStoreDownloadURL, "_blank")
    }

  return (
    <div>
    <div className="relative overflow-hidden bg-white pt-12 pb-32">
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-pagoHoyAccent">
                  <InboxIcon className="h-8 w-8 text-pagoHoyDark" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Tarifa PagoHoy: 5.3% + $3</h2>
                <p className="mt-4 text-lg text-gray-500">
                  Así de sencillo. Nuestra tarifa es transparente. 
                  Tu celular se convierte en una terminal virtual que te permite cobrar con tarjetas bancarias. 
                  Es muy fácil empezar. Descarga la app y en 5 minutos te registras para empezar a crear pagos. Compartes el link del pago y listo.
                </p>
                <div className="mt-6">
                  <a
                    onClick={openAppleDownloadURL}
                    className="inline-flex cursor-pointer rounded-lg bg-pagoHoyAccent px-4 py-1.5 text-base font-semibold leading-7 text-pagoHoyDark shadow-sm ring-1 ring-pagoHoyAccent hover:ring-pagoHoyDark"
                  >
                    Empieza ahora
                  </a>
                </div>
              </div>
            </div>


          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
            <svg
            className="absolute right-2/4 translate-x-1/2 translate-y-12 transform"
            width={884}
            height={504}
            fill="none"
            viewBox="0 0 904 784"
            aria-hidden="true"
            >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={904} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>
              <img
                className="relative mx-auto"
                width={290}
                src={require("../../assets/pghy_crearPago_android.png")}
                alt="Inbox user interface"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
            <div>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-pagoHoyAccent">
                  <SparklesIcon className="h-8 w-8 text-pagoHoyDark" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Envía recordatorios</h2>
                <p className="mt-4 text-lg text-gray-500">
                  En la sección de Historial de Pagos puedes ver de manera muy fácil qué pagos siguen pendientes. 
                  Selecciona el pago pendiente y da click en la opción de "enviar recordatorio" para enviar un email a la persona que asignaste.
                </p>
                <div className="mt-6">
                  <a
                    onClick={openAppleDownloadURL}
                    className="inline-flex cursor-pointer rounded-lg bg-pagoHoyAccent px-4 py-1.5 text-base font-semibold leading-7 text-pagoHoyDark shadow-sm ring-1 ring-pagoHoyAccent hover:ring-pagoHoyDark"
                  >
                    Empieza ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
            <div className="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
            <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform"
                width={584}
                height={504}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto"
                width={290}
                src={require("../../assets/pghy_historialPagos_android.png")} 
                alt="Customer profile user interface"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <RefreshSubheader />
    </div>
  )
}

export default Tarifa