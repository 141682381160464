// StripeReturn
import React from 'react';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import RefreshHeader from '../RefreshHeader';
import RefreshSubheader from '../RefreshSubheader';
import Instructions from '../Instructions';
import {db} from '../../Firebase'
import { getDatabase, child, get, ref, update } from "firebase/database";
import Features from '../Features';

async function fetchUserData(userId) {
    // Fetch User data from db
    const dbRef = ref(getDatabase());
    const snapshot = await get(child(dbRef, `users/${userId}`))
    const dataVal = snapshot.val();
    const nombre = dataVal.nombre;
    return nombre;
}

function writeUserAccountStatusToDB(userId) {
    const updates = {};
    updates['/users/' + userId + "/isStripeAccountVerified"] = true;
    const dbObj = getDatabase();
    update(ref(dbObj), updates)
    .then(() => { console.log("Data update SUCCESS"); })
    .catch((error) => { console.log("Data update ERROR: " + JSON.stringify(error)); });
}

function StripeReturn() {
    const [name, setName] = useState('');
    let params = useParams();
    const userId = params.userId.substring(1); // remove the colon (:) from the params string
    fetchUserData(userId).then(userNombre => {
        setName(userNombre);
    });

    // useEffect???
    writeUserAccountStatusToDB(userId);

    return (
        <>
            <Instructions userFirstName={name} />
            <Features />
            <RefreshSubheader />
        </>
    )
}

export default StripeReturn;