import React from 'react';
import { useParams } from "react-router-dom";
import RefreshHeader from '../RefreshHeader';
import RefreshSubheader from '../RefreshSubheader';
import Instructions from '../Instructions';
import {db} from '../../Firebase'
import { getDatabase, child, get, ref } from "firebase/database";

/*
The URL the user will be redirected to if:
1) the account link is expired, 
2) has been previously-visited, 
3) or is otherwise invalid. 

The URL you specify should: 
1) attempt to generate a new account link with the same parameters used to create the original account link, 
2) then redirect the user to the new account link’s URL so they can continue with Connect Onboarding. 
3) If a new account link cannot be generated or the redirect fails you should display a useful error to the user.
*/

function fetchUserData(userId) {
    // Fetch User data from db
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${userId}`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
        } else {
            console.log("No data available");
        }
    }).catch((error) => {
      console.error(error);
    });
}

function StripeRefresh() {
    const subheaderMessage = "¡Bienvenido!";
    let params = useParams();
    const userId = params.userId.substring(1); // remove the colon (:) from the params string
    console.log("StripeRefresh userId: " + userId);
    fetchUserData(userId);

    return (
        <>
            <RefreshHeader userId={userId} />
            <Instructions />
            <RefreshSubheader message={subheaderMessage} />
        </>
    )
}

export default StripeRefresh;