import React from 'react';
import {appleAppStoreDownloadURL} from '../Constants';

export default function ConfirmationHero(props) {
  function openAppleDownloadURL() {
    window.open(appleAppStoreDownloadURL, "_blank")
  }
  
 return (
  <div className="bg-white">
  <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-4xl font-bold tracking-tight text-gray-900">
        ¡Muchas gracias!
        <br />
        Tu pago ha sido exitoso.
        <br />
        <br />
        👍
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
        Hemos enviado una notificación a la persona que generó este pago confirmando que ha sido completado. 
      </p>
      <div className="mt-10 flex border-t pt-8 border-pagoHoyDark items-center justify-center gap-x-6">
        <a
          onClick={openAppleDownloadURL}
          className="rounded-md cursor-pointer bg-pagoHoyAccent px-3.5 py-1.5 text-base font-semibold leading-7 text-pagoHoyDark shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Descarga PagoHoy
        </a>
        <a href="/" className="text-base font-semibold leading-7 text-gray-900">
          Cómo funciona? <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
  </div>
</div>
 )
}