import React from 'react';
import {
    BoltIcon,
    ChatBubbleBottomCenterTextIcon,
    EnvelopeIcon,
    GlobeAltIcon,
    ScaleIcon,
  } from '@heroicons/react/24/outline'

  const transferFeatures = [
    {
      id: 1,
      name: 'Tarifas simples y transparentes',
      description:
        '5.3% + $3. Así de sencillo. Nuestra tarifa es transparente. Tu celular se convierte en una terminal virtual que te permite cobrar con tarjetas bancarias.',
      icon: GlobeAltIcon,
    },
    {
      id: 2,
      name: 'Sin cargos ocultos',
      description:
        'No hay ni habrá algún otro cargo adicional. Puedes crear todos los pagos que quieras. No hay līmites ni comisiones adicionales.',
      icon: ScaleIcon,
    },
    {
      id: 3,
      name: 'Cobra al instante',
      description:
        'Comparte el link de tu pago y recibe tu dinero a tu cuenta bancaria una vez por semana.',
      icon: BoltIcon,
    },
  ]
  const communicationFeatures = [
    {
      id: 1,
      name: 'Notificaciones móviles',
      description:
        'Recibe una notificación a tu celular al momento de que alguien te haya pagado. Así te puedes dar cuenta inmediatamente que haz recibido tu dinero.',
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      id: 2,
      name: 'Recordatorios por email',
      description:
        'Si alguien aún no te ha pagado puedes enviar un recordatorio desde la app. La persona va a recibir un email recondándoles que te tienen que pagar.',
      icon: EnvelopeIcon,
    },
  ]

function Features() {
    return (
        <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
            <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">

            <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto"
              width={290}
              src={require("../assets/pghy_crearPago_android.png")}
              alt=""
            />
        </div>

            <div id="features" className="relative mt-12 lg:mt-24 lg:grid lg:px-48 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Crea pagos. Envíalos a todo el país y el mundo.</h3>
            <p className="mt-3 text-lg text-gray-500">
              Con la app de PagoHoy puedes crear pagos con la cantidad que quieras. Asigna un email al pago y automáticamente le llegará una petición a esa persona para pagarte la cantidad que indicaste por medio de una tarjeta bancaria.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-pagoHoyAccent text-pagoHoyDark">
                      <item.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Siempre al tanto de tus pagos</h3>
              <p className="mt-3 text-lg text-gray-500">
                Con PagoHoy puedes hacer seguimiento a quién ya te pagó y quién falta por pagarte de forma muy fácil. 
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-pagoHoyAccent text-pagoHoyDark">
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto"
                width={290}
                src={require("../assets/pghy_historialPagos_android.png")} 
                alt=""
              />
            </div>
          </div>
        </div>
               
            </div>
        </div>
    )
}

export default Features;