import React from 'react';
import {appleAppStoreDownloadURL} from '../Constants';

export default function RefreshSubheader(props) {
    function openAppleDownloadURL() {
        window.open(appleAppStoreDownloadURL, "_blank")
    }

    return (
        <div className="bg-pagoHoyAccent">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 ">
            <div className="text-center">
                <h2 className="text-4xl font-bold tracking-tight text-pagoHoyDark sm:text-5xl lg:text-6xl">¡Bienvenido a PagoHoy!</h2>
                <p className="mt-5 text-xl text-gray-800">
                La manera más fácil de cobrar dinero con tarjetas bancarias.
                </p>
                <div className='py-4 px-32 xs:px-2 sm:px-16 lg:px-48 md:px-32'>
                <button
                    onClick={openAppleDownloadURL}
                    className="flex w-full items-center justify-center rounded-md border-transparent bg-pagoHoyDark py-2 px-8 text-base font-medium text-white hover:bg-pagoHoyDark hover:text-pagoHoyAccent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                    Descarga la app 📱
                    </button>
                    </div>
            </div>
            </div>
        </div>
    )
}