import React from 'react';
import HeroSection from '../HeroSection';
import Features from '../Features';
import RefreshSubheader from '../RefreshSubheader';

function Home() {
    return (
        <>
            <HeroSection />
            <Features />
            <RefreshSubheader />
        </>
    )
}

export default Home;