import React from 'react';

export default function RefreshHeader(props) {
    return (
        <div className="bg-gray-800">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:flex lg:justify-between lg:px-8">
            <div className="max-w-xl">
                <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">¡Hola, {props.userName}!</h2>
                <p className="mt-5 text-xl text-gray-400">
                Start building for free, then add a site plan to go live. Account plans unlock additional features.
                </p>
            </div>
            <div className="mt-10 w-full max-w-xs">
                <label htmlFor="currency" className="block text-base font-medium text-gray-300">
                Currency
                </label>
                <h1>User Details: {props.userName}</h1>
            </div>
            </div>
        </div>
    )
}