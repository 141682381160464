import React from 'react';
import { Outlet, Link } from "react-router-dom";

const navigation = [
    { name: 'Tarifas', href: '/tarifa' },
    { name: 'Nosotros', href: '/team' },
    { name: 'Contacto', href: '/contacto' },
  ]

function NavBar() {
  return (
    <header className="bg-pagoHoyAccent">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xs:h-24" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-pagoHoyDark py-6 lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Your Company</span>
              <img className="h-10 w-auto" src={require("../assets/pghy-logo-green-banner.png")} alt="" />
            </a>
            <div className="ml-10 space-x-8 xs:ml-6 xs:space-x-4 md:block lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} className="text-pagoHoyDark font-medium text-pagoHoyDark hover:text-pagoHoyPurple">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="md:ml-10 space-x-4 hidden">
            {/* href={`http://localhost:3000/login`} */}
            {/* href={`https://pagohoy.com/login`} */}
            <a
              href={`https://pagohoy.com/login`}
              className="xs:invisible sm:visible inline-block rounded-md border border-pagoHoyDark bg-pagoHoyDark py-2 px-4 text-white font-medium text-white hover:bg-opacity-75 cursor-pointer"
            >
              Iniciar Sesión
            </a>
            <a
              Link to={`signup/`}
              className="inline-block rounded-md border border-white bg-white py-2 px-4 text-pagoHoyDark font-medium text-pagoHoyDark hover:bg-pagoHoyDark hover:text-white hover:border-pagoHoyDark cursor-pointer"
            >
              Crear Cuenta
            </a>
          </div>
        </div>
        <div className="flex flex-wrap justify-center space-x-6 py-4 xs:hidden lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} className="text-base font-medium text-pagoHoyDark hover:text-pagoHoyPurple">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  )
}

export default NavBar;