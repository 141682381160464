import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import {db, functionNames} from '../Firebase'
import {pk, paymentSuccessReturnPrefixURL} from '../Stripe'
import { getDatabase, child, get, ref, update } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from './StripeCheckoutForm';
import GridLoader from "react-spinners/GridLoader";

const stripePromise = loadStripe(pk);


const products = [
    {
      id: 1,
      name: 'High Wall Tote',
      href: '#',
      price: '$210.00',
      color: 'White and black',
      size: '15L',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-07-product-01.jpg',
      imageAlt: 'Front of zip tote bag with white canvas, white handles, and black drawstring top.',
    },
  ]

  async function fetchPagoData(pagoDBPath) {
    // Fetch Pago data from db
    const dbRef = ref(getDatabase());
    const snapshot = await get(child(dbRef, pagoDBPath))
    const dataVal = snapshot.val();
    return dataVal;
  }

  async function fetchUserData(userId) {
    // Fetch Pago data from db
    const dbRef = ref(getDatabase());
    const snapshot = await get(child(dbRef, `users/${userId}`))
    const dataVal = snapshot.val();
    return dataVal;
  }

  function updatePaymentIntentIdInPagoObjDB(paymentIntentIdStr, pagoDBPath) {
    const updates = {};
    updates[pagoDBPath + "/stripePaymentIntentId"] = paymentIntentIdStr;
    const dbObj = getDatabase();
    update(ref(dbObj), updates)
    .then(() => { console.log("stripePaymentIntentId update SUCCESS"); })
    .catch((error) => { console.log("stripePaymentIntentId update ERROR: " + JSON.stringify(error)); });
  }

export default function PagoForm(props) {
    const pagoDBPathStr = props.pagoDBPath;
    const dbPathArr = pagoDBPathStr.split('/');
    const userId = dbPathArr[1];
    const pagoId = dbPathArr[2];
    const returnURLStr = paymentSuccessReturnPrefixURL + userId + "/" + pagoId;
    const [pagoDBPath, setPagoDBPath] = useState(pagoDBPathStr);
    // UI Data properties
    const [cantidad, setCantidad] = useState('');
    const [concepto, setConcepto] = useState('');
    const [userStripeAccountId, setUserStripeAccountId] = useState('');
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [nombreReceptor, setNombreReceptor] = useState('');
    const [emailReceptor, setEmailReceptor] = useState('');
    const [status, setStatus] = useState('Status: Pendiente');
    const [timestamp, setTimestamp] = useState('');
    const [userName, setUserName] = useState('');
    const [clientSecret, setClientSecret] = useState("");
    const [isPagoPending, setIsPagoPending] = useState(true);
    const [confirmationTimestamp, setConfirmationTimestamp] = useState("");

    const appearance = {
        theme: 'stripe',
    };
    const stripeElementOptions = {
        clientSecret,
        appearance,
        locale: 'es-419'
    };

    useEffect(() => {
        fetchPagoData(pagoDBPath).then(pagoDataObj => {
            // Moment - Process timestamp
            moment.locale('es');
            var a = moment.utc(pagoDataObj.timestamp);
            a.local();
            const timestampDisplayStr = a.format("dddd D/MMMM/YYYY H:mm")
            setTimestamp(timestampDisplayStr);
            // Set UI Data
            switch(pagoDataObj.status) {
                case 0:
                    // Pago Pending
                    setIsPagoPending(true);
                    break;
                case 1:
                    // Pago Successful
                    setIsPagoPending(false);
                    var ct = moment.utc(pagoDataObj.confirmationTimestamp);
                    ct.local();
                    const confirmationTimestampStr = ct.format("dddd D/MMMM/YYYY H:mm")
                    setConfirmationTimestamp(confirmationTimestampStr);
                    break;
                case 2:
                    // Pago Cancelled
                    setIsPagoPending(false);
                    break;
                default:
                    setIsPagoPending(true);
            }
            setCantidad(pagoDataObj.cantidad);
            setConcepto(pagoDataObj.concepto);
            setNombreReceptor(pagoDataObj.nombreReceptor);
            setEmailReceptor(pagoDataObj.emailReceptor);
            // Set Status string
            var statusStr = '';
            switch(pagoDataObj.status) {
                case 1:
                    statusStr = "Status: Pagado"
                    break;
                case 2:
                    statusStr = "Status: Cancelado"
                    break;
                default:
                    statusStr = "Status: Pendiente"        
                statusStr = "Status: Pendiente"        
                    statusStr = "Status: Pendiente"        
                statusStr = "Status: Pendiente"        
                    statusStr = "Status: Pendiente"        
                statusStr = "Status: Pendiente"        
                    statusStr = "Status: Pendiente"        
            }
            setStatus(statusStr)
            fetchUserData(userId).then(userDataObj => {
                const userNameStr = userDataObj.nombre + " " + userDataObj.apellidos;
                setUserName(userNameStr);
                setUserStripeAccountId(userDataObj.stripeAccountId);
                // createStripePaymentIntent
                if (isPagoPending) {
                    callFIRFunction(pagoDataObj.cantidad, userDataObj.stripeAccountId, pagoDataObj.emailReceptor, pagoDataObj.nombreReceptor, pagoDataObj.concepto);
                }
            });
        });

        function callFIRFunction(pagoCantidad, stripeAccountIdStr, emailReceptorStr, nombreReceptorStr, conceptoStr) {
            if (clientSecret.length == 0) {
                const functions = getFunctions();
                const pagoDescripcionStr = "Pago para " + nombreReceptorStr + ": " + conceptoStr;
                const funcParams = { 
                    cantidad: pagoCantidad, 
                    stripeAccountId: stripeAccountIdStr,
                    emailReceptor: emailReceptorStr,
                    pagoDescripcion: pagoDescripcionStr
                 };
                const createStripePaymentIntent = httpsCallable(functions, functionNames.createStripePaymentIntent);
                createStripePaymentIntent(funcParams).then((result) => {
                    // Read result of the Cloud Function.
                    const paymentIntentResult = result.data;
                    const clientSecretStr  = paymentIntentResult.clientSecret;
                    const paymentIntentIdStr = paymentIntentResult.paymentIntentId;
                    // if (paymentIntentResult.substring(1,7) === "ERROR/") {
                    //     console.log("Ocurrió un error: createStripePaymentIntent");
                    // } else {
                        setClientSecret(clientSecretStr);
                        setPaymentIntentId(paymentIntentIdStr);
                        updatePaymentIntentIdInPagoObjDB(paymentIntentIdStr, pagoDBPathStr);
                    // }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }, [clientSecret]);


    return (
        <div className="bg-white">
        {/* Background color split screen for large screens */}
        <div className="fixed top-20 left-0 hidden h-full w-1/2 bg-white lg:block" aria-hidden="true" />
        <div className="fixed top-20 right-0 hidden h-full w-1/2 bg-pagoHoyDark lg:block" aria-hidden="true" />

        <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
            <h1 className="sr-only">Checkout</h1>

            <section
            aria-labelledby="summary-heading"
            className="bg-pagoHoyDark py-4 text-white md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-pagoHoyDark lg:px-0 lg:pt-0 lg:pb-24"
            >
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                <h2 id="summary-heading" className="sr-only">
                Order summary
                </h2>

                <dl>
                <dt className="text-4xl text-white font-bold">¡Hola, {nombreReceptor}!</dt>
                <dt className="mt-3 text-xl text-white font-medium">{userName} te ha enviado un pago.</dt>
                <dt className="mt-3 text-xl text-white font-medium">El concepto del pago es:</dt>
                </dl>

                <ul role="list" className="divide-y divide-white divide-opacity-10 text-sm font-medium">
                {products.map((product) => (
                    <li key={product.id} className="flex items-start space-x-4 py-6">
                        {/*
                    <img
                        src={product.imageSrc}
                        alt={product.imageAlt}
                        className="h-20 w-20 flex-none rounded-md object-cover object-center"
                    />
                    */}
                    <div className="flex-auto space-y-1">
                        <h3 className="text-pagoHoyAccent text-lg">{concepto}</h3>
                        <div className="py-5"></div>
                        <p className="text-white">{status}</p>
                        <p className="text-white">Creado: {timestamp}</p>

                    </div>
                    <p className="flex-none text-lg font-medium text-white">${cantidad}</p>
                    </li>
                ))}
                </ul>
                <dl className="space-y-6 pt-2 text-sm font-medium">
                <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                    <dt className="text-2xl">Total a pagar:</dt>
                    <dd className="text-2xl">${cantidad}</dd>
                </div>
                </dl>    
            </div>
            </section>

            {isPagoPending ? (
                <section
                aria-labelledby="payment-and-shipping-heading"
                className="py-1 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24"
                style={{"height" : "550px"}}
                >
                <h2 id="payment-and-shipping-heading" className="sr-only">
                    Payment and shipping details
                </h2>
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
                    <div className="mt-1">
                        <h3 className="text-lg mb-6 font-medium text-gray-900">Ingresa los datos de tu tarjeta bancaria:</h3>
                        {/* Stripe Elements Checkout*/}
                        <div>
                            {clientSecret ? (
                                <Elements options={stripeElementOptions} stripe={stripePromise}>
                                    <StripeCheckoutForm returnURL={returnURLStr} />
                                </Elements>
                            ) : (
                                <div className="absolute flex mt-6 items-center justify-center" style={{"width" : "30%"}}>
                                    <GridLoader color="#72FF91" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                </section>
            ) : (
                <div className="py-4 px-4" style={{"height" : "350px"}}>
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        ¡Tu pago ha sido exitoso!
                    </h1>
                    <p className="mt-6 text-pagoHoyDark">Fecha de pago: {confirmationTimestamp}</p>
                </div>
            )}

            
        </div>
        </div>
    )
}