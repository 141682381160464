import React from 'react'

const GradientSubheader = () => {
  return (
    <div>
        <div className="bg-gradient-to-r from-pagoHoyAccent to-pghyAccentDark">
            <div className="py-8 px-6 sm:px-6 sm:py-8 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-4xl font-bold tracking-tight text-pagoHoyDark">
                    Nuestra historia.
                    <br />
                    Como fue que se me ocurrió desarrollar PagoHoy.
                </h2>
                <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-800">
                   Todo empezó por andar distraído. Me fui sin pagar un servicio y ni me di cuenta.
                </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GradientSubheader