import React from 'react';
import {appleAppStoreDownloadURL} from '../Constants';
import { HashLink as Link } from 'react-router-hash-link';

function HeroSection() {
    function openAppleDownloadURL() {
        window.open(appleAppStoreDownloadURL, "_blank")
    }

    return (
        <div className="lg:relative bg-pagoHoyDark">
            <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left bg-pagoHoyDark">
            <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span className="block xl:inline text-white">Recibe pagos con Tarjeta</span>{' '}
                <span className="block text-pagoHoyAccent xl:inline">¡En 5 minutos!</span>
                </h1>
                <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl text-white">
                Con PagoHoy te registras en 5 minutos y puedes empezar a recibir pagos con tarjeta.
                </p>
                <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                    <Link 
                    to="#features"
                    className="flex w-full items-center justify-center rounded-md border border-pagoHoyAccent bg-pagoHoyAccent px-8 py-3 text-pagoHoyDark font-medium text-pagoHoyDark hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                    >
                    ¿Cómo funciona?
                    </Link>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <button
                    onClick={openAppleDownloadURL}
                    className="flex w-full items-center justify-center rounded-md border border-white bg-white px-8 py-3 text-base font-medium text-pagoHoyDark hover:bg-gray-50 md:py-4 md:px-10 md:text-lg"
                    >
                    Descarga la app 📱
                    </button>
                </div>
                </div>
            </div>
            </div>
            <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                alt=""
            />
            </div>
        </div>
    )
}

export default HeroSection;