import React, {useState} from 'react'
import * as LogSnag from '../../LogSnag';

const Contact = (props) => {
  const [nombre, setNombre] = useState("")
  const [apellido, setApellido] = useState("")
  const [email, setEmail] = useState("")
  const [empresa, setEmpresa] = useState("")
  const [telefono, setTelefono] = useState("")
  const [enteraste, setEnteraste] = useState("")
  const [mensaje, setMensaje] = useState("")

  async function sendContactFormEmail(event) {
    event.preventDefault();
    if (email.length > 0) {
      props.onNotify("Gracias por ponerte en contacto. Nosotros te responderemos lo mas pronto posible.")
      const description = "email: " + email + 
                          " nombre: " + nombre + " " + apellido + 
                          " empresa: " + empresa +
                          " telefono: " + telefono + 
                          " enteraste: " + enteraste + 
                          " mensaje: " + mensaje;
      await LogSnag.createLogSnagEvent(LogSnag.userContactChannel, LogSnag.webContactoFormEvent, description)
      setNombre("")
      setApellido("")
      setEmail("")
      setEmpresa("")
      setTelefono("")
      setEnteraste("")
      setMensaje("")
    }
  }


  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1563761886-05f84b8e0da9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8TWV4aWNvLDF8fHx8fHwxNjc0NTE1NzAz&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
            alt=""
          />
        </div>
      </div>
      <div className="relative py-16 px-6 sm:py-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-32">
        <div className="lg:pr-8">
          <div className="mx-auto max-w-md sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">¡Hola y gracias por visitarnos!</h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              Nos gustaría escuchar de ti. Ingresa tus datos en la parte inferior y cuéntanos cualquier duda o ayuda que necesites.
            </p>
            <form action="#" method="POST" className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
              <div>
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Nombre
                </label>
                <div className="mt-1">
                  <input
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Apellido
                </label>
                <div className="mt-1">
                  <input
                    value={apellido}
                    onChange={(e) => setApellido(e.target.value)}
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                  Empresa
                </label>
                <div className="mt-1">
                  <input
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Teléfono
                  </label>
                  <span id="phone-description" className="text-sm text-gray-500">
                    Opcional
                  </span>
                </div>
                <div className="mt-1">
                  <input
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    aria-describedby="phone-description"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="how-did-you-hear-about-us" className="block text-sm font-medium text-gray-700">
                ¿Cómo te enteraste de PagoHoy?
                </label>
                <div className="mt-1">
                  <input
                    value={enteraste}
                    onChange={(e) => setEnteraste(e.target.value)}
                    type="text"
                    name="how-did-you-hear-about-us"
                    id="how-did-you-hear-about-us"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label htmlFor="how-can-we-help" className="block text-sm font-medium text-gray-700">
                    ¿Cómo te podemos ayudar?
                  </label>
                  <span id="how-can-we-help-description" className="text-sm text-gray-500">
                    Máx. 500 caracteres
                  </span>
                </div>
                <div className="mt-1">
                  <textarea
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                    id="how-can-we-help"
                    name="how-can-we-help"
                    aria-describedby="how-can-we-help-description"
                    rows={4}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    defaultValue={''}
                  />
                </div>
              </div>

              <div className="text-right sm:col-span-2">
                <button
                  onClick={sendContactFormEmail}
                  className="inline-flex justify-center rounded-md border-transparent bg-pagoHoyAccent py-2 px-4 text-md font-medium text-pagoHoyDark shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-pagoHoyDark focus:ring-offset-2"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact