import React from 'react';
import PagoForm from '../PagoForm';
import { useParams } from "react-router-dom";

export default function Pago(props) {
  // Get route params
  let params = useParams();    
  const userId = params.userId;
  const pagoId = params.pagoId;
  const pagoDBPathStr = "pagos/" + userId + "/" + pagoId;

  return (
      <>
        <PagoForm pagoDBPath={pagoDBPathStr}/>
      </>
  )
}