import React from 'react'
import RefreshSubheader from '../RefreshSubheader'
import GradientSubheader from '../GradientSubheader'

const people = [
    {
      name: 'Salvador Rodríguez Dávila',
      role: 'Creador de PagoHoy',
      imageUrl:
        'src={require("../assets/pghy_crearPago_android.png")}',
      bio: 'Soy un ingeniero de software altamente experimentado con más de 9 años de experiencia en la industria tecnológica. Soy un profesional versátil con un sólido conocimiento en el desarrollo y mantenimiento de sistemas a gran escala, aplicaciones móviles y diseño de UI/UX. Tengo una gran experiencia en el desarrollo de software, específicamente en las plataformas React Native, Web, iOS y Android. También un gran conocimiento en principios de diseño centrado en el usuario, los cuales aplica para crear interfaces intuitivas y fáciles de usar. En mi tiempo libre me gusta tocar guitarra y correr maratones.',
      twitterUrl: 'https://twitter.com/srdzdv',
      linkedinUrl: 'https://www.linkedin.com/in/salvador-rodr%C3%ADguez-d%C3%A1vila-59316025/',
    },
  ]

const Team = () => {
  return (
    <div>
        <GradientSubheader />


        <div className="overflow-hidden bg-white">
        <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
            <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
            <div>
                <h2 className="text-lg font-semibold text-indigo-600">Agosto 2020</h2>
                <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Dublin, CA</h3>
            </div>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:col-start-2 lg:row-start-1">
                <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
                >
                <defs>
                    <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                    >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                </svg>
                <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
                <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                        className="rounded-lg object-cover object-center shadow-lg"
                        src="https://images.unsplash.com/photo-1522092787785-60123fde65c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8TWV4aWNvLDF8fHx8fHwxNjc0NTgyODc0&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"

                        alt="Whitney leaning against a railing on a downtown street"
                        width={1184}
                        height={1376}
                    />
                    </div>
                </figure>
                </div>
            </div>
            <div className="mt-8 lg:mt-0">
                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                <p className="text-lg text-gray-500">
                Hace unos años, estaba en el taller mecánico dándole servicio a mi coche. Me di cuenta antes de llevarlo que mi tapón frontal izquierdo ya no estaba en mi llanta. Al ingresar mi coche al taller le pedí al agente que busque si tenían disponible un tapón nuevo. El agente me dijo que sí lo tenían disponible. Le contesté que sí quería comprar uno para que de una vez saliera del taller con el nuevo tapón. 
                </p>
                </div>
                <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
                <p>
                Este taller te avisa por medio de mensajes de texto cuando tu auto está listo. Después de una hora y media de haber ingresado al taller me llega el mensaje de texto avisando que mi auto está listo. En ese momento, por estar en mi laptop tratando de arreglar un problema en una aplicación móvil que estaba programando en ese momento, se me olvidó por completo que había pedido el tapón. Ya sabía que los servicios ya estaban prepagados, así que sólo me subí a mi auto y me fui. 
                </p>
                <p>
                Unos cinco minutos después me entra una llamada. Contesto. Es el agente del taller preguntando si ya me había ido. Le digo que sí, muchas gracias por el servicio. Me contesta diciendo que me fui sin pagar el nuevo tapón. Me pongo rojo porque se me había pasado pagar. Le digo al agente que todavía estoy cerca, puedo regresar al taller de inmediato para realizar el pago. Me contesta que no es necesario. Me dice que me va a llegar un mensaje de texto con un link para completar el pago desde mi celular. 
                </p>
                <p>
                ¡Wow! Es la primera vez en mi experiencia que una tienda me permite finalizar una transacción sin tener que estar en la tienda. En ese momento se me prende el foco. Empiezo a imaginar todo el software que se tiene que programar para lograr algo así.
                </p>
                <ul role="list">
                    <li>Una app que te permite ingresar los detalles del pago</li>
                    <li>El servidor que haga el procesamiento del pago</li>
                    <li>Una página web que puedas compartir con los detalles del pago y poder ingresar los datos de una tarjeta bancaria para completar la transacción</li>
                </ul>
                <p>
                Después de varios meses de trabajo duro, finalmente lancé mi aplicación en la App Store en enero de 2023. 
                </p>
                <h3>Mi Meta</h3>
                <p>
                Mi aplicación se convirtió en un éxito y me permitió seguir adelante con mi carrera como desarrollador de aplicaciones. Todo comenzó con un simple olvido de llevar mi cartera al supermercado, pero terminó llevando a algo mucho más grande y emocionante.
                </p>
                <p>
                Con PagoHoy espero que muchas personas que necesitan aceptar pagos con tarjetas bancarias puedan hacerlo muy fácil sin tener que registrarse para conseguir una terminal de banco. 
                </p>
                </div>
            </div>
            </div>
        </div>
        </div>



        <div className="bg-white">
        <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Acerca de Nosotros</h2>
                <p className="text-xl text-gray-500">
                El equipo se compone de una sola persona. Yo. 
                Espero que PagoHoy te ayude a resolver el problema de coboros con tarjeta de manera fácil. 
                </p>
            </div>
            <div className="lg:col-span-2">
                <ul
                role="list"
                className="space-y-12 sm:-mt-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 lg:gap-x-8 lg:space-y-0"
                >
                {people.map((person) => (
                    <li key={person.name} className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                        <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                        <img className="rounded-lg object-cover shadow-lg" src={require("../../assets/salvador_dic22.jpg")} alt="" />
                        </div>
                        <div className="sm:col-span-2">
                        <div className="space-y-4">
                            <div className="space-y-1 text-lg font-medium leading-6">
                            <h3>{person.name}</h3>
                            <p className="text-indigo-600">{person.role}</p>
                            </div>
                            <div className="text-lg">
                            <p className="text-gray-500">{person.bio}</p>
                            </div>
                            <ul role="list" className="flex space-x-5">
                            <li>
                                <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                                </a>
                            </li>
                            <li>
                                <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">LinkedIn</span>
                                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                    />
                                </svg>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </li>
                ))}
                </ul>
            </div>
            </div>
        </div>
        </div>


        <RefreshSubheader />
    </div>
  )
}

export default Team