import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import ConfirmationHero from '../ConfirmationHero';
import RefreshSubheader from '../RefreshSubheader';
import {db, functionNames} from '../../Firebase'
import { getDatabase, ref, update } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as LogSnag from '../../LogSnag';

export default function PagoConfirmado(props) {
  // Get route params
  let params = useParams();    
  const userId = params.userId;
  const pagoId = params.pagoId;
  const pagoDBPathStr = "pagos/" + userId + "/" + pagoId;

  function updatePagoStatusToDB(pagoDBPath) {
    const updates = {};
    updates[pagoDBPath + "/status"] = 1;
    const d = new Date();
	  const timestamp = d.toISOString();
    updates[pagoDBPath + "/confirmationTimestamp"] = timestamp;

    const dbObj = getDatabase();
    update(ref(dbObj), updates)
    .then(() => { console.log("Data update SUCCESS");})
    .catch((error) => { console.log("Data update ERROR: " + JSON.stringify(error)); });
  }

  function sendPushNotificationToMobile() {
    console.log("sendPushNotificationToMobile");
    const functions = getFunctions();
    const funcParams = { 
      userId: userId, 
      pagoId: pagoId,
     };
    const triggerPushNotification = httpsCallable(functions, functionNames.triggerPushNotification);
    triggerPushNotification(funcParams).then((result) => {
        console.log("triggerPushNotification: " + result.data);
    }).catch((error) => {
        console.log("ERROR/triggerPushNotification: " + error);
    });     
  }

  function sendConfirmationEmail() {
    console.log("sendConfirmationEmail");
    const functions = getFunctions();
    const funcParams = { 
      userId: userId,
      pagoId: pagoId,
     };
    const triggerConfirmationEmail = httpsCallable(functions, functionNames.triggerConfirmationEmail);
    triggerConfirmationEmail(funcParams).then((result) => {
        console.log("triggerConfirmationEmail: " + result.data);
    }).catch((error) => {
        console.log("ERROR/triggerConfirmationEmail: " + error);
    });   
  }

  async function creatLogSnagEvent() {
      const description = "Success";
      await LogSnag.createLogSnagEvent(LogSnag.pagoCreadoChannel, LogSnag.pagoCompletadoEvent, description)
  }

  useEffect(() => {
    updatePagoStatusToDB(pagoDBPathStr);
    sendPushNotificationToMobile();
    sendConfirmationEmail();
    creatLogSnagEvent();
  });

  return (
      <>
        <ConfirmationHero />
        <RefreshSubheader />
      </>
  )
}