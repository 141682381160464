import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

var firebaseConfig = {
    apiKey: "AIzaSyCdhJzOBcibwtIfqNMsz8s4AjK9ph9RO0o",
    authDomain: "pagohoy-b5bfe.firebaseapp.com",
    projectId: "pagohoy-b5bfe",
    storageBucket: "pagohoy-b5bfe.appspot.com",
    messagingSenderId: "608738679489",
    appId: "1:608738679489:web:3029478339ae4a1b931240",
    measurementId: "G-RLVE6K3JXM",
    databaseURL: "https://pagohoy-b5bfe-default-rtdb.firebaseio.com/",
};

  // Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getDatabase(app)
const auth = getAuth(app);

export const functionNames = {
  createStripePaymentIntent: "createStripePaymentIntent",
  triggerPushNotification: "triggerPushNotification",
  triggerConfirmationEmail: "triggerConfirmationEmail",
  triggerContactForm: "triggerContactFormEmail"
};

export {db, auth}