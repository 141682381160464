import React from 'react';
import NavBar from './components/NavBar';
import Home from './components/pages/Home';
import Footer from './components/Footer';
import StripeRefresh from './components/pages/StripeRefresh';
import StripeReturn from './components/pages/StripeReturn';
import Pago from './components/pages/Pago';
import Privacy from './components/pages/Privacy';
import PagoConfirmado from './components/pages/PagoConfirmado';
import Login from './components/pages/Login';
import Contact from './components/pages/Contact';
import Team from './components/pages/Team';
import Tarifa from './components/pages/Tarifa';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const handleNotify = (toastString) => {
    console.log("handleNotify")
    toast.success(toastString, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  if (window.location.host.split(".")[0] == "app") {
    // It checks if the subdomain is named app. 
    // If so, navigate to the dashboard component.
    return (
      <Router>
        <NavBar />
      </Router>
    )
  } else {
    return (
      <div className='App'>
        <Router>
          <NavBar />
          <ToastContainer />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='refresh/:userId' element={<StripeRefresh />} />
            <Route path='return/:userId' element={<StripeReturn />} />
            <Route path='pago/:userId/:pagoId' element={<Pago />} />
            <Route path='pagoconfirmado/:userId/:pagoId' element={<PagoConfirmado />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='login' element={<Login />} />
            <Route path='contacto' element={<Contact onNotify={handleNotify} />} />
            <Route path='team' element={<Team />} />
            <Route path='tarifa' element={<Tarifa />} />
          </Routes>
          <Footer onNotify={handleNotify} />
        </Router>
      </div>
    )
  }
}

export default App;